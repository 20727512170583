import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import type { Meta } from "@/__main__/router.mjs";
import { appURLs } from "@/app/constants.mjs";
import Layout from "@/feature-reskin-probuilds-net/components/Layout.jsx";
import ArticleList from "@/marketing/ArticleList.jsx";
import Container from "@/shared/ContentContainer.jsx";
import { CONTENTFUL_TAGS } from "@/shared/contentful-constants.mjs";
import PageHeader from "@/shared/PageHeader.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export function Guides() {
  const {
    contentful: { related },
  } = useSnapshot(readState);
  const { t } = useTranslation();
  const relatedArticles = useMemo(
    () =>
      related.filter((i) =>
        i.tags.some((j) => j === CONTENTFUL_TAGS.PROBUILDS),
      ),
    [related],
  );
  if (!relatedArticles.length) return null;
  return (
    <Layout>
      <PageHeader
        image={`${appURLs.CDN}/probuildsnet/logomini.webp`}
        imageAlt="Pro Builds abbreviated"
        title={t("probuilds:title.proGuides", "Pro Guides")}
      />
      <hr style={{ margin: "var(--sp-4) 0" }} className="invis" />
      <Container>
        <h2 style={{ display: "none" }}>
          {t(
            "probuilds:title.guides",
            "League of Legends ARAM Builds, Counters and Guides",
          )}
        </h2>
        <ArticleList
          articles={relatedArticles.map((i) => ({
            id: i.id,
            coverImageUrl: i.imageUrl,
            title: i.title,
            excerpt: i.excerpt,
            createdAt: i.createdAt,
            link: `/article/${i.id}`,
          }))}
        />
      </Container>
    </Layout>
  );
}

Guides.showAppNavigation = true;

export default Guides;

export function meta(): Meta {
  return {
    title: [
      "probuildsnet:articles.meta.title",
      "Game Guides, News and Articles Library - Pro Builds",
    ],
    description: [
      "probuildsnet:articles.meta.description",
      "Browse our extensive article library. Discover diverse insights, expert opinions, and stories on any topic.",
    ],
    subtitle: true,
  };
}
